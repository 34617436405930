import React from 'react';
import {
  DocumentOutlineIcon,
  ClockOutlineIcon,
  DocumentCheckOutlineIcon,
  CheckMarkOutlineIcon,
  CloseOutlineIcon,
} from '@applyboard/ui-icons';
import { Flex, Text, Card as FrameCard, Avatar, Tag } from '@applyboard/crystal-ui';
import { TruncatedButtonLink } from '~/components/uie-fix/TruncatedButtonLink';
import { Attachment } from '~/pages/ApplicationDetails/data/useApplication';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';
import moment from 'moment';
import { Card } from './Card';
import { Decision } from '@ab/ams-school-api/src/resources/applications/studentDecisions';

function DecisionLetterCard(attachmentDocument: Attachment, decision?: Decision) {
  function getDocumentTile(attachmentDocument: Attachment) {
    if (attachmentDocument.type.code === 'REJECTION_LETTER') {
      return 'Decline Letter';
    } else if (attachmentDocument.type.code === 'WAITLIST_LETTER') {
      return 'Waitlist Letter';
    } else if (attachmentDocument.type.code === 'FINAL_OFFER_LETTER') {
      return 'Final Offer Letter';
    } else {
      return 'Initial Offer Letter';
    }
  }

  function getDocumentAvatar(attachmentDocument: Attachment) {
    if (attachmentDocument.type.code === 'REJECTION_LETTER') {
      return (
        <Avatar
          aria-label="rejection-letter"
          emphasis="highlighted"
          icon={CloseOutlineIcon}
          intent="negative"
          size="md"
        />
      );
    } else if (attachmentDocument.type.code === 'WAITLIST_LETTER') {
      return (
        <Avatar
          aria-label="waitlist-letter"
          emphasis="highlighted"
          icon={ClockOutlineIcon}
          intent="secondary"
          size="md"
        />
      );
    } else if (attachmentDocument.type.code === 'FINAL_OFFER_LETTER') {
      return (
        <Avatar
          aria-label="final-offer-letter"
          emphasis="highlighted"
          icon={CheckMarkOutlineIcon}
          intent="positive"
          size="md"
        />
      );
    } else {
      return (
        <Avatar
          aria-label="pre-offer-letter"
          emphasis="highlighted"
          icon={CheckMarkOutlineIcon}
          intent="positive"
          size="md"
        />
      );
    }
  }
  return (
    <FrameCard>
      <Flex direction="column" gap={8}>
        <Flex direction="row" justify="between" gap={8}>
          <Flex.Item>
            <Flex direction="row" align="center" justify="center" gap={6}>
              <Flex.Item>{getDocumentAvatar(attachmentDocument)}</Flex.Item>
              <Flex.Item>
                <Text variant="titleS" contrast="high" intent="secondary">
                  {getDocumentTile(attachmentDocument)}
                </Text>
              </Flex.Item>
              <Flex.Item>
                <Tag intent="positive" size="sm">
                  {`Issued on ${moment(attachmentDocument.updatedAt).format('MMM DD, YYYY')}`}
                </Tag>
              </Flex.Item>
            </Flex>
          </Flex.Item>
          <Flex.Item>
            <TruncatedButtonLink
              aria-label={`Download ${attachmentDocument.fileName} document`}
              emphasis="highlighted"
              href={attachmentDocument.downloadUrl!}
              intent={attachmentDocument.validationStatus === 'VALID' ? 'positive' : 'primary'}
              size="lg"
              leadIcon={DocumentCheckOutlineIcon}
              width="hug"
              mode="override_user_choice_and_open_in_new_tab"
            >
              {attachmentDocument.fileName}
            </TruncatedButtonLink>
          </Flex.Item>
        </Flex>

        {decision && (
          <Flex direction="row" gap={36} justify="start">
            <Flex.Item>
              <Text variant="labelS" intent="secondary">
                OFFER STATUS
              </Text>
              {getDecisionStatusIcon(decision?.studentResponse || 'PENDING')}
            </Flex.Item>
            {decision?.decisionDate && decision.studentResponse === 'ACCEPTED' && (
              <Flex.Item>
                <Text variant="labelS" intent="secondary">
                  ACCEPTED ON
                </Text>
                <Tag intent="secondary" size="sm">
                  {moment(decision.decisionDate).format('MMM DD, YYYY')}
                </Tag>
              </Flex.Item>
            )}
            {decision?.decisionDate && decision.studentResponse === 'REJECTED' && (
              <Flex.Item>
                <Text variant="labelS" intent="secondary">
                  REJECTED ON
                </Text>
                <Tag intent="secondary" size="sm">
                  {moment(decision.decisionDate).format('MMM DD, YYYY')}
                </Tag>
              </Flex.Item>
            )}

            {decision?.reason && (
              <Flex.Item>
                <Text variant="labelS" intent="secondary">
                  REASON OF REJECTION
                </Text>
                <Text variant="labelM" intent="secondary" contrast="low">
                  {decision?.reason}
                </Text>
              </Flex.Item>
            )}
          </Flex>
        )}

        {/* {attachmentDocument.type.code === 'REJECTION_LETTER' && (
          <Flex direction="row" gap={36} justify="start">
            <Flex.Item>
              <Text variant="labelS" intent="secondary">
                School Declination Reason
              </Text>
              <Text variant="labelM" intent="secondary" contrast="low">
                Decided to purse admission at another University
              </Text>
            </Flex.Item>
          </Flex>
        )} */}
      </Flex>
    </FrameCard>
  );
}
export function DecisionLetters() {
  const {
    application: { preOfferLetter, rejectionLetter, studentDecisions, waitlistLetter, finalOfferLetter },
  } = useAppProcessing();
  return (
    <Card hideChevron id="decisionLetters" icon={DocumentOutlineIcon} title="Decision Letters">
      <Flex gap={8} direction="column">
        {preOfferLetter && (
          <Flex.Item>
            {DecisionLetterCard(preOfferLetter, {
              ...studentDecisions?.preOfferDecision,
              decisionDate: studentDecisions?.preOfferDecision?.decisionDate
                ? new Date(studentDecisions?.preOfferDecision?.decisionDate)
                : undefined,
              studentResponse: studentDecisions?.preOfferDecision?.studentResponse || 'PENDING',
            })}
          </Flex.Item>
        )}
        {waitlistLetter && <Flex.Item>{DecisionLetterCard(waitlistLetter)}</Flex.Item>}
        {finalOfferLetter && <Flex.Item>{DecisionLetterCard(finalOfferLetter)}</Flex.Item>}
        {rejectionLetter && <Flex.Item>{DecisionLetterCard(rejectionLetter)}</Flex.Item>}
      </Flex>
    </Card>
  );
}

const getDecisionStatusIcon = (decisionStatus: string) => {
  switch (decisionStatus) {
    case 'ACCEPTED':
      return (
        <Tag intent="positive" size="sm">
          Student Accepted Offer
        </Tag>
      );
    case 'REJECTED':
      return (
        <Tag intent="negative" size="sm">
          Student Rejected Offer
        </Tag>
      );
    case 'PENDING':
      return (
        <Tag intent="warning" size="sm">
          Acceptance Pending
        </Tag>
      );
    default:
      return;
  }
};
